import React from 'react';
import logo from './logo.svg';
import './App.css';
import {RootState, store} from "./store";
import {GET_IMAGES} from "./constants/actionTypes";
import agent from "./agent";
import {useSelector} from "react-redux";

function App() {
  agent.setBasic("juanlubel:juanlubel")
  getImagesFromRegistry("https://registry.spu-labs.dev")
  const {images} = useSelector((store: RootState) => store.registry)
  console.log(images)
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

function getImagesFromRegistry(registry: string) {
  store.dispatch({
    type: GET_IMAGES,
    payload: agent.Images.all(registry)
  })
}

export default App;
