import { configureStore } from "@reduxjs/toolkit"
import registryReducer from "./reducers/registry";
import {promiseMiddleware} from "./middleware";
// ...

export const store: any = configureStore({
    reducer: {
        registry: registryReducer
    },
    devTools: {
        name: 'Conduit',
    },
    middleware: [promiseMiddleware],
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch