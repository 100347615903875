// @ts-ignore
import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import registry from "./reducers/registry";

const superagent = superagentPromise(_superagent, global.Promise);

// const API_ROOT = process.env.REACT_APP_BACKEND_URL || 'https://conduit.productionready.io/api';

const encode = encodeURIComponent;
const responseBody = (res: any) => res.body;

let token: string | null;
let basic: string | null;
const authHeader = (registry: string) => (req: any) => {
    if (token) {
        req.set('authorization', `Token ${token}`);
    }
    if (basic) {
        // req.set('Access-Control-Allow-Origin', [registry])
        // req.set('Access-Control-Allow-Credentials', [true])
        // req.set('Access-Control-Allow-Headers', ['Authorization', 'Accept'])
        // req.set('Access-Control-Allow-Origin', registry)
        req.auth(basic.split(":")[0], basic.split(":")[1])
    }
}

const requests = {
    del: (registry: string, url: string) =>
        superagent.del(`${registry}${url}`).use(authHeader).then(responseBody),
    get: (registry: string, url: string) =>
        superagent.get(`${registry}${url}`).use(authHeader(registry)).then(responseBody),
    put: (registry: string, url: string, body: any) =>
        superagent.put(`${registry}${url}`, body).use(authHeader).then(responseBody),
    post: (registry: string, url: string, body: any) =>
        superagent.post(`${registry}${url}`, body).use(authHeader).then(responseBody)
};

const Auth = {
    // current: () =>
    //     requests.get('/user'),
    // login: (email, password) =>
    //     requests.post('/users/login', { user: { email, password } }),
    // register: (username, email, password) =>
    //     requests.post('/users', { user: { username, email, password } }),
    // save: user =>
    //     requests.put('/user', { user })
};

// const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
// const omitSlug = article => Object.assign({}, article, { slug: undefined })
const Images = {
    all: (registry: string) =>
        requests.get(registry, `/v2/_catalog`),
};


export default {
    Images,
    Auth,
    setToken: (_token: string | null) => {
        token = _token;
    },
    setBasic: (_basicAuth: string | null) => {
        basic = _basicAuth;
    }
};