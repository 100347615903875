import {
    GET_IMAGES
} from "../constants/actionTypes";
import {AnyAction, createSlice} from "@reduxjs/toolkit";

interface AppState {
    images: string[]
}

const initialState: AppState = {
    images: []
}

// export default registryReducer
//
// function registryReducer(state: initialState = _initialState, {payload, type}: AnyAction) {
//     switch (type) {
//         case GET_IMAGES:
//             return {
//                 ...state,
//                 images: payload
//             }
//         default : {
//             return state
//         }
//     }
// }

const slice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        GET_IMAGES: (state, action) => {
          state.images = action.payload
        }
    },
});

export default slice.reducer;